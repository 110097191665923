import fetch from "auth/FetchInterceptor";
import {
	AUTH_TOKEN_ADMIN,
	AUTH_TOKEN_EMPRESA,
	AUTH_TOKEN_TESTADOR,
} from "redux/constants/Auth";

const JwtAuthService = {};

// TODO: após clonar o skeleton, ajustar essa função
JwtAuthService.login = function (
	email,
	password,
	login_type,
	googleToken = null,
) {
	let body;

	if (googleToken) {
		// Login com Google
		body = { google_token: googleToken, login_type };
	} else {
		// Login tradicional
		body = { email, senha: password, login_type };
	}

	return fetch({
		url: "/v1/rpc/login",
		method: "post",
		headers: {
			"public-request": "true",
		},
		data: body,
	});
};

JwtAuthService.signUp = function (data) {
	return fetch({
		url: "/auth/signup",
		method: "post",
		headers: {
			"public-request": "true",
		},
		data: data,
	});
};

JwtAuthService.isLogged = () => {
	const url = window.location.href;

	const isAdm = url.includes("adm-app") || url.includes("adm-auth");
	const isEmpresa =
		url.includes("empresa-app") || url.includes("empresa-auth");
	const isTestador =
		url.includes("testador-app") || url.includes("testador-auth");
	if (isAdm) {
		return localStorage.getItem(AUTH_TOKEN_ADMIN) !== null;
	}
	if (isEmpresa) {
		return localStorage.getItem(AUTH_TOKEN_EMPRESA) !== null;
	}
	if (isTestador) {
		return localStorage.getItem(AUTH_TOKEN_TESTADOR) !== null;
	}
};

export default JwtAuthService;
