import {
	BankOutlined,
	UserOutlined,
	UsergroupAddOutlined,
	RobotOutlined,
	DashboardOutlined,
	ApartmentOutlined,
	TrophyOutlined,
	FileUnknownOutlined,
	ShoppingOutlined,
	FormOutlined,
	SecurityScanOutlined,
	TeamOutlined,
	DeploymentUnitOutlined,
	FileExcelOutlined,
	TagsOutlined,
} from "@ant-design/icons";
import { ADM_APP_PREFIX_PATH } from "configs/AppConfig";
import {
	PermissaoCategoriaAdmEnum,
	PermissaoEmpresaAdmEnum,
	PermissaoGrupoUsuarioAdmEnum,
	PermissaoGrupoUsuarioEmpresaAdmEnum,
	PermissaoParceiroAdmEnum,
	PermissaoPesquisaProdutoAdmEnum,
	PermissaoPesquisaSubcategoriaAdmEnum,
	PermissaoProdutoAdmEnum,
	PermissaoProdutoNaoCadastradoAdmEnum,
	PermissaoRespostaPesquisaAdmEnum,
	PermissaoSubcategoriaAdmEnum,
	PermissaoUsuarioAdmEnum,
	PermissaoUsuarioEmpresaAdmEnum,
	PermissaoUsuarioTestadorAdmEnum,
} from "../enum/permissao-adm-enum";
import { PermissaoMarcaEnum } from "enum/permissao-enum";
// import {
// 	PermissaoGrupoUsuarioEnum,
// 	PermissaoUsuarioEnum,
// 	PermissaoCargoEnum,
// 	PermissaoSegmentoProfissionalEnum,
// 	PermissaoEmpresaEnum,
// 	PermissaoCategoriaProdutoEnum,
// } from "enum/permissao-enum";

const dashBoardNavTree = [
	{
		key: "painel",
		title: "painel",
		submenu: [
			{
				key: "dashboard",
				path: `${ADM_APP_PREFIX_PATH}/dashboard`,
				title: "dashboard",
				icon: DashboardOutlined,
				breadcrumb: false,
				submenu: [],
			},
			{
				key: "lista-campanhas",
				path: `${ADM_APP_PREFIX_PATH}/lista-campanhas`,
				title: "lista-campanhas",
				icon: TrophyOutlined,
				breadcrumb: false,
				submenu: [],
				//permissao: PermissaoCampanhaAdmEnum.VISUALIZAR,
			},
			// {
			// 	key: "relatorios",
			// 	path: `${ADM_APP_PREFIX_PATH}/relatorios`,
			// 	title: "relatorios",
			// 	icon: ExclamationCircleOutlined,
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
			{
				key: "lista-categorias",
				path: `${ADM_APP_PREFIX_PATH}/lista-categorias`,
				title: "lista-categorias",
				icon: ApartmentOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoCategoriaAdmEnum.VISUALIZAR,
			},
			{
				key: "lista-subcategorias",
				path: `${ADM_APP_PREFIX_PATH}/lista-subcategorias`,
				title: "lista-subcategorias",
				icon: DeploymentUnitOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoSubcategoriaAdmEnum.VISUALIZAR,
			},
			{
				key: "lista-produtos",
				path: `${ADM_APP_PREFIX_PATH}/lista-produtos`,
				title: "lista-produtos",
				icon: ShoppingOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoProdutoAdmEnum.VISUALIZAR,
			},
			// {
			// 	key: "logs",
			// 	path: `${ADM_APP_PREFIX_PATH}/logs`,
			// 	title: "logs",
			// 	icon: ReloadOutlined,
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
			{
				key: "lista-parceiros",
				path: `${ADM_APP_PREFIX_PATH}/lista-parceiros`,
				title: "lista-parceiros",
				icon: TeamOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoParceiroAdmEnum.VISUALIZAR,
			},
			{
				key: "lista-marcas",
				path: `${ADM_APP_PREFIX_PATH}/lista-marcas`,
				title: "lista-marcas",
				icon: TagsOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoMarcaEnum.VISUALIZAR,
			},
		],
	},
	{
		key: "pesquisas",
		title: "pesquisas",
		submenu: [
			{
				key: "lista-pesquisa-produto",
				path: `${ADM_APP_PREFIX_PATH}/lista-pesquisa-produto`,
				title: "lista-pesquisa-produto",
				icon: FileUnknownOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoPesquisaProdutoAdmEnum.VISUALIZAR,
			},
			{
				key: "lista-pesquisa-subcategoria",
				path: `${ADM_APP_PREFIX_PATH}/lista-pesquisa-subcategoria`,
				title: "lista-pesquisa-subcategoria",
				icon: SecurityScanOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoPesquisaSubcategoriaAdmEnum.VISUALIZAR,
			},
		],
	},
	{
		key: "respostas",
		title: "respostas",
		submenu: [
			{
				key: "lista-respostas-pesquisa",
				path: `${ADM_APP_PREFIX_PATH}/lista-respostas-pesquisa`,
				title: "lista-respostas-pesquisa",
				icon: FormOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoRespostaPesquisaAdmEnum.VISUALIZAR,
			},
		],
	},
	{
		key: "beneficio",
		title: "beneficio",
		submenu: [
			{
				key: "lista-beneficio",
				path: `${ADM_APP_PREFIX_PATH}/lista-beneficio`,
				title: "lista-beneficio",
				icon: BankOutlined,
				breadcrumb: false,
				submenu: [],
			},
			{
				key: "lista-resgate-beneficio",
				path: `${ADM_APP_PREFIX_PATH}/lista-resgate-beneficio`,
				title: "lista-resgate-beneficio",
				icon: BankOutlined,
				breadcrumb: false,
				submenu: [],
			},
		],
	},
	{
		key: "empresas",
		title: "empresas",
		submenu: [
			{
				key: "lista-empresas",
				path: `${ADM_APP_PREFIX_PATH}/lista-empresas`,
				title: "lista-empresas",
				icon: BankOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoEmpresaAdmEnum.VISUALIZAR,
			},
			{
				key: "lista-usuarios-empresa",
				path: `${ADM_APP_PREFIX_PATH}/lista-usuarios-empresa`,
				title: "lista-usuarios-empresa",
				icon: UserOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoUsuarioEmpresaAdmEnum.VISUALIZAR,
			},
			{
				key: "lista-grupos-usuario-empresa",
				path: `${ADM_APP_PREFIX_PATH}/lista-grupos-usuario-empresa`,
				title: "lista-grupos-usuario-empresa",
				icon: UsergroupAddOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoGrupoUsuarioEmpresaAdmEnum.VISUALIZAR,
			},
		],
	},
	{
		key: "testadores",
		title: "testadores",
		submenu: [
			{
				key: "lista-testadores",
				path: `${ADM_APP_PREFIX_PATH}/lista-testadores`,
				title: "lista-testadores",
				icon: RobotOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoUsuarioTestadorAdmEnum.VISUALIZAR,
			},
			// {
			// 	key: "lista-top-testadores",
			// 	path: `${ADM_APP_PREFIX_PATH}/lista-top-testadores`,
			// 	title: "lista-top-testadores",
			// 	icon: StarOutlined,
			// 	breadcrumb: false,
			// 	submenu: [],
			// },
		],
	},
	{
		key: "cadastros-secundario",
		title: "cadastros-secundario",
		breadcrumb: false,
		submenu: [
			{
				key: "lista-produto-nao-cadastrado",
				path: `${ADM_APP_PREFIX_PATH}/lista-produto-nao-cadastrado`,
				title: "lista-produto-nao-cadastrado",
				icon: FileExcelOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoProdutoNaoCadastradoAdmEnum.VISUALIZAR,
			},
		],
	},
	{
		key: "gerenciamento-usuario",
		title: "gerenciamento-usuario",
		breadcrumb: false,
		submenu: [
			{
				key: "lista-usuarios",
				path: `${ADM_APP_PREFIX_PATH}/lista-usuarios`,
				title: "lista-usuarios",
				icon: UserOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoUsuarioAdmEnum.VISUALIZAR,
			},
			{
				key: "lista-grupos-usuario",
				path: `${ADM_APP_PREFIX_PATH}/lista-grupos-usuario`,
				title: "lista-grupos-usuario",
				icon: UsergroupAddOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoGrupoUsuarioAdmEnum.VISUALIZAR,
			},
		],
	},
	{
		key: "apenas-homolog",
		title: "apenas-homolog",
		breadcrumb: false,
		submenu: [
			{
				key: "excluir-dados-homolog",
				path: `${ADM_APP_PREFIX_PATH}/homologacao`,
				title: "excluir-dados-homolog",
				icon: FileExcelOutlined,
				breadcrumb: false,
				submenu: [],
			},
		],
	},
];

const admNavigationConfig = [...dashBoardNavTree];

export default admNavigationConfig;
